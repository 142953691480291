import React, { Component } from 'react'
import styles from './stylesheets/header.module.sass'
import { NavDropdown } from 'react-bootstrap'
import Menu from './components/Menu'
import Search from './components/Search'
import jumpTo,{go} from '../../modules/Navigation'
import Auth from '../../modules/Auth'
import device from '../../modules/mediaQuery'
import MediaQuery from 'react-responsive'
import {client} from '../../modules/Client'

export default class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      input: '',
      isToggle: false
    }
  }

  processAuthResult = (err, authResult) => {
    if (authResult && authResult.authorized_challenge) {
        localStorage.setItem('token', JSON.stringify(authResult))
        window.location.href = '/login';
    } else if (err) {
        console.log(err);
        window.location.href = '/dashboard';
    };
  };

  login = () => {
    client.authorize({
        csrfToken: async function () {
            const response = await fetch(`${process.env.REACT_APP_SPONSOR_HOST}/csrf`);
            const result = await response.json();
            return result.token
        },
        challenge: "knkW5N5R9sfRppQxJYfg44gfdgdV7QknkW5N5R9sfRppQV7QJ",
        scope: 'VelasAccountProgram:Transfer VelasAccountProgram:Execute EVM:Execute authorization'
    }, this.processAuthResult);
  };

  handleChange = (v) => {
    this.setState({
      input: v
    })
  }
  handleSuggest = (v) => {
    this.setState({
      input: v
    })
  }
  handleToggle = () => {
    this.setState(prevState => {
      return {
        isToggle: !prevState.isToggle
      }
    })
  }
  closeToggle = () => {
    this.setState({
      isToggle: false
    })
  }
  render() {
    const { user_token,
      departments,
      search,
      getProductsByCategory,
      getAllProducts } = this.props
    let visibility = "hide"
    if (this.state.isToggle) {
      visibility = "show"
    }
    return (
      <div className={styles.outbox}>
        {/* larger than 768px */}
        <MediaQuery query={device.min.tablet}>
          {/* top user header */}
          {/* menu header */}
          <div className={styles.content}>
            <div className={styles.left}>
              {/* logo */}
              <div className={styles.logo}
                onClick={() => {
                  getAllProducts()
                  jumpTo('/dashboard')
                }}
              >
                Demo Shop
              </div>
            </div>
            <div className={styles.mid}>
              <Menu
                departments={departments}
                getProductsByCategory={getProductsByCategory}
                getAllProducts={getAllProducts}
              />
            </div>
            <div className={styles.right}>
              <div className={styles.tag} onClick={() => window.history.href = window.location.href = '/bag'}>
                <svg height="16px" width="16px" x="0px" y="0px" viewBox="0 0 456.029 456.029"> <g> <g> <path d="M345.6,338.862c-29.184,0-53.248,23.552-53.248,53.248c0,29.184,23.552,53.248,53.248,53.248    c29.184,0,53.248-23.552,53.248-53.248C398.336,362.926,374.784,338.862,345.6,338.862z"/> </g> </g> <g> <g> <path d="M439.296,84.91c-1.024,0-2.56-0.512-4.096-0.512H112.64l-5.12-34.304C104.448,27.566,84.992,10.67,61.952,10.67H20.48    C9.216,10.67,0,19.886,0,31.15c0,11.264,9.216,20.48,20.48,20.48h41.472c2.56,0,4.608,2.048,5.12,4.608l31.744,216.064    c4.096,27.136,27.648,47.616,55.296,47.616h212.992c26.624,0,49.664-18.944,55.296-45.056l33.28-166.4    C457.728,97.71,450.56,86.958,439.296,84.91z"/> </g> </g> <g> <g> <path d="M215.04,389.55c-1.024-28.16-24.576-50.688-52.736-50.688c-29.696,1.536-52.224,26.112-51.2,55.296    c1.024,28.16,24.064,50.688,52.224,50.688h1.024C193.536,443.31,216.576,418.734,215.04,389.55z"/> </g> </g> </svg>
                Cart
              </div>

              {(user_token && Object.keys(user_token).length > 0)
                ?
                <div className={styles.loggout}>
                  <NavDropdown title={`hello, ${user_token.user_name.slice(0,6)}..${user_token.user_name.substr(-6)}`}>
                    <NavDropdown.Item onClick={Auth.logout} href='/'>
                      logout
                    </NavDropdown.Item>
                  </NavDropdown>
                </div>
                : 
                <div className={styles.loggout}>
                  <div className={styles.login} onClick={this.login}>
                    Login
                </div>
                </div>
              }
            </div>
          </div>
        </MediaQuery>
        {/* smaller than 768px */}
        <MediaQuery query={device.max.tablet}>
          <div className={styles.content}>
            <div className={`${styles.toggle_outbox}`}>
              {/* toggle content */}
              <div id="toggle" className={styles[`${visibility}`]}>
                <div className={styles.toggle_content}>
                  <div className={styles.side_title}>
                    MENU
                    <div
                      className={styles.side_title_close}
                      onClick={this.closeToggle}
                    >
                      x
                    </div>
                  </div>
                  <Search
                    search={search}
                    onChange={this.handleChange}
                    input_value={this.state.input}
                    handleSuggest={this.handleSuggest}
                  />
                  <div className={styles.side_title}>
                    CATEGORY
                  </div>
                  <Menu
                    departments={departments}
                    getProductsByCategory={getProductsByCategory}
                    getAllProducts={getAllProducts}
                  />
                  <div className={styles.side_title}>
                    CART
                  </div>
                  <div
                    className={styles.side_content}
                    onClick={() => jumpTo('/bag')}
                  >
                    Shopping Bag
                  </div>
                  <div className={styles.side_title}>
                    USER
                  </div>
                  <div
                    className={styles.side_content}
                    onClick={() => jumpTo('/login')}
                  >
                    Login
                  </div>
                  <div
                    className={styles.side_content}
                    onClick={() => { Auth.logout(); go('/dashboard') }}
                  >
                    Logout
                  </div>
                </div>
              </div>
              {/* toggle icon */}
              <div className={`${styles.toggle_icon} ${styles[`${visibility}`]}`} onClick={this.handleToggle}>
                <div className={styles.bar1}></div>
                <div className={styles.bar2}></div>
                <div className={styles.bar3}></div>
              </div>
            </div>
            {/* logo */}
            <div className={styles.logo}
              onClick={() => {
                getAllProducts()
                jumpTo('/dashboard')
              }}
            >
              Demo Shop
            </div>
          </div>
        </MediaQuery>
      </div >
    )
  }
}



