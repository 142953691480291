import React, { Component } from 'react'
import HeaderContainer from '../../components/header/headerContainer'
import Product from './components/Product'
import LoadingAnimation from '../../components/loadingAnimation'
import Filter from './components/Filter'
import styles from './stylesheets/dashboard.module.sass'


export default class Dashboard extends Component {

  componentDidMount() {
    if (!this.props.products) {
      this.props.getAllProducts()
    }
  }
  
  render() {
    const { products, applyFilters } = this.props
    return (
      <div className={styles.outbox}>
        {/* Header */}
        <HeaderContainer />

        <svg height="100%" width="100%" id="svg" viewBox="0 0 1440 700" xmlns="http://www.w3.org/2000/svg" className="background-wave"><path d="M 0,700 C 0,700 0,233 0,233 C 109.0622009569378,208.0909090909091 218.1244019138756,183.1818181818182 305,201 C 391.8755980861244,218.8181818181818 456.5645933014355,279.3636363636364 547,262 C 637.4354066985645,244.63636363636363 753.6172248803829,149.36363636363635 857,152 C 960.3827751196171,154.63636363636365 1050.9665071770335,255.1818181818182 1146,285 C 1241.0334928229665,314.8181818181818 1340.5167464114834,273.9090909090909 1440,233 C 1440,233 1440,700 1440,700 Z" stroke="none" strokeWidth="0" fill="#fbd87088" className="transition-all duration-300 ease-in-out delay-150" transform="rotate(-180 720 350)"></path><path d="M 0,700 C 0,700 0,466 0,466 C 105.55023923444975,473.98086124401914 211.1004784688995,481.9617224880382 303,466 C 394.8995215311005,450.0382775119618 473.1483253588517,410.13397129186603 561,408 C 648.8516746411483,405.86602870813397 746.3062200956938,441.5023923444976 856,439 C 965.6937799043062,436.4976076555024 1087.6267942583734,395.8564593301436 1187,394 C 1286.3732057416266,392.1435406698564 1363.1866028708132,429.0717703349282 1440,466 C 1440,466 1440,700 1440,700 Z" stroke="none" strokeWidth="0" fill="#fbd870ff" className="transition-all duration-300 ease-in-out delay-150" transform="rotate(-180 720 350)"></path></svg>
        <div className={styles.box}>
          {/* loading animation */}
          {this.props.loading &&
            <LoadingAnimation />
          }
          {/* filter */}
          <div className={styles.filter}>
            <Filter
              applyFilters={applyFilters}
            />
          </div>
          {/* products */}
            <div className={`row ${styles.products}`}>
              {products && products.map(p =>
                <div
                  key={p.title}
                  className={`col-12 col-sm-6 col-md-12 col-lg-4 my-4 ${styles.product}`}
                  onClick={() => this.props.history.push(`/product-overview/${p._id}`)}>
                  <Product
                    title={p.title}
                    price={`$${p.price} USD`}
                    color={p.color}
                    image={p.imagePath}
                  />
                </div>
              )}
            </div>
        </div>
      </div>
    )
  }
}





