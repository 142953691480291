import React, { useEffect } from 'react'
import Button from './components/Button'
import LoadingAnimation from '../../components/loadingAnimation'

import jumpTo from '../../modules/Navigation'

import {client} from '../../modules/Client'

const challenge = "knkW5N5R9sfRppQxJYfg44gfdgdV7QknkW5N5R9sfRppQV7QJ"

export default function Login({ postToken }) {

  const processAuthResult = (err, authResult) => {
    if (authResult && authResult.authorized_challenge) {
        postToken(authResult.authorized_challenge).then(res => {
          localStorage.setItem('token', JSON.stringify(authResult))
          jumpTo('/dashboard') 
          console.log(res)
          return res
        }).catch(error => {
          console.log(error.response)
          alert(error.response.data.error.message)
          return error
        })
    } else if (err) {
        console.log(err);
        jumpTo('/dashboard')
    };
  };

  const checkAuthorization = () => {

    let data    = localStorage.getItem('token');
    let session = false;

    try {
        session = JSON.parse(data);
    } catch (_) {};

    if (session) {
      processAuthResult(null, session)
    } else {
      client.handleRedirectCallback( processAuthResult);
    };
  };

  const login = () => {
    client.authorize({
        csrfToken: async function () {
            const response = await fetch(`${process.env.REACT_APP_SPONSOR_HOST}/csrf`);
            const result = await response.json();
            return result.token
        },
        challenge,
        scope: 'VelasAccountProgram:Transfer VelasAccountProgram:Execute EVM:Execute authorization'
    }, processAuthResult);
  };

  useEffect(checkAuthorization, []);

  return (
    <div>
      <Button button_title={"test"} onClick={login} />
      <LoadingAnimation />
    </div>
  )
}





